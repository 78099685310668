.leaflet-div-icon
{
  background: #323232;
  border:3px solid rgba(214, 213, 213, 0.5);
  color:blue;
  font-weight:bold;
  text-align:center;
  border-radius:50%;
  line-height:15px;
  width: 10px;
  height: 10px;
}

.gps-icon
{
  background: #323232;
  border:3px solid rgba(214, 213, 213, 0.5);
  color:blue;
  font-weight:bold;
  text-align:center;
  border-radius:50%;
  line-height:30px;
  width: 10px;
  height: 10px;
  margin-left: -8px;
}

.recoleccion-marker
{
  background: transparent;
  border: 0px transparent;
  margin-left: -19px;
  margin-top: -39px;
  background-image:url(https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fic%2Fmic16.png?alt=media&token=a1a8cd23-acd1-4498-9942-a6e608ca98db);
  background-repeat: no-repeat;
  background-size: 30px 30px;
  width:30px;
  height:30px;
}

.moto-marker
{
  background: transparent;
  border: 0px transparent;
  margin-left: -19px;
  margin-top: -39px;
  background-image:url(https://firebasestorage.googleapis.com/v0/b/hawk-admin.appspot.com/o/hawk-imgs%2FmotoMap.png?alt=media&token=ec63c006-9a07-4297-9424-deb58492b78b);
  background-repeat: no-repeat;
  background-size: 30px 30px;
  width:30px;
  height:30px;
}

.recoleccion-marker2
{
  background: transparent;
  border: 0px transparent;
  margin-left: -19px;
  margin-top: -39px;
  background-image:url(https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fic%2Fmic9.png?alt=media&token=4ebfb9a6-2b77-4ee2-afff-6f8123f23a38);
  background-repeat: no-repeat;
  background-size: 30px 30px;
  width:30px;
  height:30px;
}

.entrega-marker
{
  background: transparent;
  border: 0px transparent;
  margin-left: -19px;
  margin-top: -39px;
  background-image:url(https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fic%2Fmic15.png?alt=media&token=0dde29c3-27fc-4fdc-a58c-9e28e6719c8f);
  background-repeat: no-repeat;
  background-size: 30px 30px;
  width:30px;
  height:30px;
}

.entrega-marker2
{
  background: transparent;
  border: 0px transparent;
  margin-left: -19px;
  margin-top: -39px;
  background-image:url(https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fic%2Fmic10.png?alt=media&token=50ecddbb-7008-48ab-805d-d6868e8ac814);
  background-repeat: no-repeat;
  background-size: 30px 30px;
  width:30px;
  height:30px;
}

.marker-text
{
  position: absolute;
  left: 7px;
  top: -35px;
  color: red;
  font-size: 14px;
  font-weight: 800;
}

.circle {
    position: absolute;
    border-radius: 10%;
    text-align: center;
    font-size: 14px;
    color: white;
    font-weight: 800;
    left: 2px;
    top: -66px;
    background: #212121;
    padding: 6px 8px 4px 8px;
  }