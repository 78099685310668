.play-button {
    position: absolute;
    bottom: 50px;
    left: '50%';
    width: 150px;
    height: 60px;
    z-index: 9999;
    margin: 20px;
    margin-top: 10px;
    overflow: auto;
    border-radius: 12px;
  }
  
  .back-button {
    position: absolute;
    left: 100;
    width: 150px;
    height: 60px;
    z-index: 9999;
    margin: 20px;
    margin-top: 10px;
    overflow: auto;
    border-radius: 12px;
  }